import React from "react";

// Define Props for Tooltip
interface TooltipProps {
  children: React.ReactNode;
  content: string;
  isVisible: boolean;
}

// Tooltip Component remains a simple presentational component.
export function Tooltip({ children, content, isVisible }: TooltipProps) {
  return (
    <div className="relative flex items-center">
      {children}
      {isVisible && (
        <div
          className="absolute top-10 left-6 transform -translate-x-1/2 px-2 py-1 text-xs font-bold retro:text-gray-900 font-sans secondary-bg rounded-md shadow-lg"
          style={{ whiteSpace: "nowrap" }}
        >
          {content}
        </div>
      )}
    </div>
  );
}
