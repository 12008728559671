import { Introduction } from '../../components/Introduction';
import { Contact } from '../../components/Contact';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function About() {
  const contactRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to top when the About page loads
    window.scrollTo(0, 0);
    
    // If there's a hash in the URL and it matches "#contact", scroll to it
    if (location.hash === '#contact' && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location]);

  return (
    <>
      <Introduction />
      
      {/* Contact Section */}
      <div id="contact" ref={contactRef}>
        <Contact />
      </div>
    </>
  );
}
