import { useState, useEffect } from 'react';

// Define all available themes
type Theme = 'light' | 'dark' | 'vintage'  | 'autumn' | 'nature'| 'future' | 'winter' | 'retro';

// Theme mode classification
const lightModeThemes: Theme[] = ['light', 'vintage', 'nature', 'winter'];
const darkModeThemes: Theme[] = ['dark', 'retro', 'future', 'autumn'];

export function useTheme() {
  // Initialize theme from localStorage or system preference
  const [theme, setTheme] = useState<Theme>(() => {
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme') as Theme;
      
      // Check if saved theme is valid
      if (
        savedTheme === 'light' || 
        savedTheme === 'dark' || 
        savedTheme === 'vintage' || 
        savedTheme === 'autumn' ||
        savedTheme === 'nature' || 
        savedTheme === 'future' || 
        savedTheme === 'winter' ||
        savedTheme === 'retro'
      ) {
        return savedTheme;
      }
      
      // Default to dark if prefers-color-scheme is dark, otherwise light
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return 'light';
  });

  // Apply theme to document when it changes
  useEffect(() => {
    const root = window.document.documentElement;
    
    // Remove all theme classes
    root.classList.remove(
      'light', 'dark', 'vintage', 'nature', 'retro', 'future', 'winter', 'autumn'
    );
    
    // Add current theme class
    root.classList.add(theme);
    
    // Save to localStorage
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Determine if current theme is light or dark mode
  const isLightMode = lightModeThemes.includes(theme);
  const isDarkMode = darkModeThemes.includes(theme);

  // Toggle through all themes in sequence
  const toggleTheme = () => {
    setTheme((prevTheme) => {
      switch (prevTheme) {
        case 'light': return 'dark';
        case 'dark': return 'vintage';
        case 'vintage': return 'autumn';
        case 'autumn': return 'nature';
        case 'nature': return 'future';
        case 'future': return 'winter';
        case 'winter': return 'retro';
        case 'retro': return 'light';
        default: return 'dark';
      }
    });
  };

  // Set a specific theme directly
  const setSpecificTheme = (newTheme: Theme) => {
    setTheme(newTheme);
  };

  return { 
    theme, 
    toggleTheme, 
    setTheme: setSpecificTheme,
    isLightMode,
    isDarkMode
  };
}