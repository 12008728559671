import { LineChart, Code, Database } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SectionHeader } from '../utils/SectionHeader';

const experience = [
  {
    year: '2022',
    role: 'Role 1',
    company: 'Company A',
    description: 'Worked on dummy projects and learned a lot.',
  },
  {
    year: '2021',
    role: 'Role 2',
    company: 'Company B',
    description: 'Handled dummy responsibilities and tasks.',
  },
  {
    year: '2020',
    role: 'Role 3',
    company: 'Company C',
    description: 'Collaborated on various dummy initiatives.',
  },
];

const skills = [
  {
    icon: Code,
    title: 'Programming',
    items: ['HTML', 'CSS', 'JavaScript', 'TypeScript'],
  },
  {
    icon: Database,
    title: 'Data Handling',
    items: ['SQL', 'NoSQL', 'Data Analysis'],
  },
  {
    icon: LineChart,
    title: 'Analytics',
    items: ['Google Analytics', 'Dummy Metrics', 'Reporting'],
  },
];

const fadeInUpVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0, 
    transition: { duration: 0.4 }
  }
};

// const fadeInVariant = {
//   hidden: { opacity: 0, y: 0 },
//   visible: { 
//     opacity: 1, 
//     y: 0, 
//     transition: { duration: 0.3 }
//   }
// };

export function Introduction() {
  const aboutRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash) {
      if (location.hash === '#contact' && contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (location.hash === '#about' && aboutRef.current) {
        aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [location.hash]);

  return (
    <section
      id="about"
      ref={aboutRef}
      className="py-10"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader title="About Me" />

        {/* Experiences Section */}
        <div className="mb-20">
          <h3 className="text-2xl font-bold primary-text mb-8">
            Experiences
          </h3>
          <div className="space-y-8">
            {experience.map((item, index) => (
              <motion.div
                key={index}
                variants={fadeInUpVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                className="relative pl-8 border-l-2 accent-border"
              >
                <div className="absolute -left-2 top-0 w-4 h-4 rounded-full accent-bg" />
                <div className="space-y-2">
                  <span className="text-sm accent-text font-medium">
                    {item.year}
                  </span>
                  <h4 className="text-lg font-semibold primary-text">
                    {item.role}
                  </h4>
                  <p className="secondary-text font-medium">
                    {item.company}
                  </p>
                  <p className="secondary-text">
                    {item.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Skills Section */}
        <div className="mb-20">
          <h3 className="text-2xl font-bold primary-text mb-8">
            Skills & Expertise
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {skills.map((skill, index) => (
              <motion.div
                key={index}
                variants={fadeInUpVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                className="p-6 secondary-bg rounded-xl hover:shadow-sm transition-shadow"
              >
                <skill.icon className="w-12 h-12 accent-text mb-4" />
                <h4 className="text-xl font-semibold primary-text mb-4">
                  {skill.title}
                </h4>
                <div className="flex flex-wrap gap-2">
                  {skill.items.map((item, i) => (
                    <span
                      key={i}
                      className="px-3 py-1 navbar-bg accent-text rounded-full text-sm"
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
