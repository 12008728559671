import { motion } from 'framer-motion';
import { ArrowRight, ExternalLink } from 'lucide-react';
import { projects } from '../data/projects';
import { publications } from '../data/publications';
import { Link } from 'react-router-dom';
import { SectionHeader } from '../utils/SectionHeader';

export function FeaturedWorks() {
  const featuredProjects = projects.filter(project => project.featured);
  const featuredPublications = publications.filter(publication => publication.featured);

  return (
    <section id="work" className="relative py-10 overflow-hidden">
      <div className="absolute inset-0 bg-grid-pattern opacity-5" />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-blue-900/10" />
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader title="Recent Works" />

        {/* Projects Section */}
        <div className="mb-16">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-3xl font-bold primary-text">
              Projects
            </h3>
            <Link
              to="/portfolio"
              className="hidden sm:flex group items-center space-x-2 accent-text"
            >
              <span>View All Projects</span>
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {featuredProjects.slice(0, 7).map((project, index) => (
              <motion.a
                key={project.id}
                href={project.pathUrl}
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="group relative aspect-[3/2] rounded-xl overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="absolute inset-0">
                  <img
                    src={project.imageUrl}
                    alt={project.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    loading="lazy"
                  />
                  {/* Overlay with updated background and stacking */}
                  <div className="absolute inset-0 bg-project-overlay" />
                </div>
                
                <div className="absolute inset-0 p-4 flex flex-col justify-end project-text-overlay">
                  <div className="space-y-2">
                    <div className="flex flex-wrap gap-2">
                      {project.tools.map(tool => (
                        <span
                          key={tool}
                          className="px-2 py-1 text-xs bg-white/10 backdrop-blur-sm rounded-full text-white"
                        >
                          {tool}
                        </span>
                      ))}
                    </div>
                    <h3 className="text-lg font-semibold text-white">
                      {project.title}
                    </h3>
                    <p className="text-gray-200 text-sm line-clamp-2">
                      {project.description}
                    </p>
                  </div>
                </div>
              </motion.a>
            ))}
            
            <div className="flex justify-end items-center">
              <Link
                to="/portfolio"
                className="flex group sm:hidden items-center space-x-2 accent-text"
              >
                <span>View All Projects</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>

        {/* Publications Section */}
        <div>
          <div className="flex justify-between items-center mb-2 pb-2 font-sans">
            <h3 className="text-3xl font-bold primary-text">
              Publications
            </h3>
            <Link
              to="/research"
              className="hidden sm:flex group items-center space-x-2 accent-text"
            >
              <span>View All Publications</span>
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>

          <div className="space-y-4">
            {featuredPublications.slice(0, 3).map((publication, index) => (
              <motion.div
                key={publication.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-publication rounded-lg p-6 py-4 hover:shadow-sm transition-shadow"
              >
                <div className="space-y-1.5">
                  <div className="space-y-1">
                    <h3 className="text-lg font-semibold primary-text">
                      {publication.title}
                    </h3>
                    <p className="text-sm secondary-text">
                      {publication.journal} • {publication.date}
                    </p>
                  </div>
                  <p className="text-sm secondary-text leading-relaxed">
                    {publication.abstract}
                  </p>
                  <a
                    href={publication.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center space-x-2 accent-text"
                  >
                    <span>Read more</span>
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </motion.div>
            ))}
            
            <div className="flex justify-end items-center">
              <Link
                to="/research"
                className="flex group sm:hidden items-center space-x-2 accent-text"
              >
                <span>View All Publications</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
