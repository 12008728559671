import { motion } from 'framer-motion';
import { ArrowLeft, Search } from 'lucide-react';
import { projects } from '../../data/projects';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { SectionHeader } from '../../utils/SectionHeader';

export function Portfollio() {
  const location = useLocation();
  const projectsRef = useRef<HTMLDivElement>(null);
  const publicationsRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Get distinct categories from projects data.
  const categories = ['All', ...Array.from(new Set(projects.map((p) => p.category)))];

  useEffect(() => {
    if (location.hash === '#projects' && projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (location.hash === '#publications' && publicationsRef.current) {
      publicationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // const filteredProjects = projects.filter(project =>
  //   project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //   project.description.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  
  // Filter by search query and category (if not alll)
  const filteredProjects = projects.filter((project) => {
    const matchesSearch =
      project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      project.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory =
      selectedCategory === 'All' || project.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  // const featuredProjects = projects.filter((project) => project.featured);

  return (
    <>
      <section className="relative py-10 overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-5" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-blue-900/10" />

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <SectionHeader title="My Portfolio" />


          <div className="flex items-center justify-center space-x-4 mb-4">
            {/* Category Filter Buttons */}
            <div className="flex items-center space-x-2">
              {categories.map((cat) => (
                <motion.button
                  key={cat}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSelectedCategory(cat)}
                  className={`px-3 py-1 rounded-full border text-base transition-colors ${
                    selectedCategory === cat
                      ? 'secondary-bg border-color'
                      : 'border-color'
                  }`}
                >
                  {cat}
                </motion.button>
              ))}
            </div>
          </div>

          {/* Projects Section */}
          <div className="mb-16" id="projects" ref={projectsRef}>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-3xl font-bold">
                Projects
              </h3>
              <motion.div
                className="relative"
                initial={{ width: 40 }}
                animate={{ width: searchExpanded ? 300 : 50 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                {searchExpanded ? (
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search projects..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onBlur={() => setSearchExpanded(false)}
                      autoFocus
                      className="w-full px-4 py-2 h-10 pl-10 rounded-full bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-1"
                    />
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                      <Search className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                    </div>
                  </div>
                ) : (
                  <motion.button
                    onClick={() => setSearchExpanded(true)}
                    whileTap={{ scale: 0.96 }}
                    className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-800"
                  >
                    <Search className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </motion.button>
                )}
              </motion.div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.map((project, index) => (
                <motion.a
                  key={project.id}
                  href={project.pathUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative aspect-[3/2] rounded-xl overflow-hidden"
                >
                  <div className="absolute inset-0">
                    <img
                      src={project.imageUrl}
                      alt={project.title}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-purple-900/90 via-purple-900/50 to-transparent" />
                  </div>
                  <div className="absolute inset-0 p-4 flex flex-col justify-end">
                    <div className="space-y-2">
                      <div className="flex flex-wrap gap-2">
                        {project.tools.map((tool) => (
                          <span
                            key={tool}
                            className="px-2 py-1 text-xs bg-white/10 backdrop-blur-sm rounded-full text-white"
                          >
                            {tool}
                          </span>
                        ))}
                      </div>
                      <h3 className="text-xl font-bold text-white">{project.title}</h3>
                      <p className="text-gray-200 text-sm line-clamp-2">{project.description}</p>
                    </div>
                  </div>
                </motion.a>
              ))}
            </div>
          </div>

          <div className="mt-12">
            <Link
              to="/"
              className="group flex items-center space-x-2 text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300"
            >
              <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
              <span>Go Back to Home</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
