export interface Project {
  id: string;
  title: string;
  description: string;
  tools: string[];
  pathUrl: string;
  imageUrl: string;
  category: string;
  featured?: boolean;
}

export const projects: Project[] = [
  {
    id: "quantum-ml",
    title: "Systema Mercaturae Qiskit-Based",
    description: "Systema mercaturae altae frequentiae quod machinam discendi quantica ad analysis mercatus emendandam utitur.",
    tools: ["Qiskit", "Python", "TensorFlow"],
    pathUrl: "https://github.com/Tariqve/quantum-ml",
    imageUrl: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=500&h=300&q=50",
    category: "AI/ML",
    featured: true
  },
  {
    id: "neural-architecture",
    title: "Quaestio Architecturae Neuralis",
    description: "Optimatio automata architecturae retis neuralis utens disciplina refectionis.",
    tools: ["PyTorch", "JAX", "Ray"],
    pathUrl: "https://github.com/Tariqve/nas",
    imageUrl: "https://images.unsplash.com/photo-1620712943543-bcc4688e7485?auto=format&fit=crop&w=500&h=300&q=50",
    category: "AI/ML",
    featured: true
  },
  {
    id: "sustainable-city",
    title: "Optimatorem Urbis Intelligens",
    description: "Systema consilii urbanorum quod intelligentia artificialis ad progressionem urbis sustinendam utitur.",
    tools: ["TensorFlow", "GIS", "Python"],
    pathUrl: "https://github.com/Tariqve/smart-city",
    imageUrl: "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Engineering",
    featured: true
  },
  {
    id: "market-sentiment",
    title: "Analyticus Affectus Mercatus",
    description: "Analysis affectus mercatus in tempore reali utens NLP et data socialium retia.",
    tools: ["BERT", "FastAPI", "Redis"],
    pathUrl: "https://github.com/Tariqve/sentiment",
    imageUrl: "https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Quantitative Finance",
    featured: true
  },
  {
    id: "autonomous-drone",
    title: "Systema Dronorum Autonomorum",
    description: "Systema navigationis dronorum autonomorum potestate visionis computatralis.",
    tools: ["PyTorch", "ROS", "C++"],
    pathUrl: "https://github.com/Tariqve/drone-ai",
    imageUrl: "https://images.unsplash.com/photo-1508614589041-895b88991e3e?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Engineering",
    featured: true
  },
  {
    id: "personalized-tutor",
    title: "Platea Disciplinae Adaptivae",
    description: "Platea disciplinae personalis quae stilis et celeritatibus discendi singulorum se accommodat utens discendo profundo.",
    tools: ["Python", "TensorFlow", "Flask"],
    pathUrl: "https://github.com/Tariqve/ai-tutor",
    imageUrl: "https://images.unsplash.com/photo-1553356084-58ef4a67b2a7?auto=format&fit=crop&w=500&h=300&q=50",
    category: "AI/ML",
    featured: false
  },
  {
    id: "medical-image-analysis",
    title: "Systema Analysis Imagium Medicorum",
    description: "Systema discendi profundi ad detegendas anomalias in imaginibus medicis utens retibus convolutionalibus neuralibus.",
    tools: ["PyTorch", "CUDA", "Docker"],
    pathUrl: "https://github.com/Tariqve/medical-ai",
    imageUrl: "https://images.unsplash.com/photo-1631563019676-dade0dbdb8fc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fG1lZGljYWwlMjBpbWFnaW5nfGVufDB8fDB8fHww",
    category: "AI/ML",
    featured: false
  },
  {
    id: "supply-chain-optimization",
    title: "Optimatorem Logisticarum Catenae Suppletionis",
    description: "Systema ad optimizandum logistica catenae suppletionis utens programmando limitationibus et analyticis praedictivis.",
    tools: ["Python", "Gurobi", "AWS"],
    pathUrl: "https://github.com/Tariqve/supply-chain",
    imageUrl: "https://images.unsplash.com/photo-1556761175-b413da4baf72?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Engineering",
    featured: false
  },
  {
    id: "algorithmic-trading",
    title: "Platea Mercaturae Algorithmicae",
    description: "Platea mercaturae algorithmicae altae perfomantia utens exemplis statisticis provectis et alimentis datarum in tempore reali.",
    tools: ["Python", "Pandas", "NumPy", "Kafka"],
    pathUrl: "https://github.com/Tariqve/algo-trading",
    imageUrl: "https://images.unsplash.com/photo-1737301214226-3f959016436f?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Quantitative Finance",
    featured: false
  },
  {
    id: "portfolio-optimizer",
    title: "Optimatorem Riscorum Portfolios",
    description: "Systema ad optimizandum portfolios investmentorum utens theoria portfolii moderna et technicis administrationis periculi.",
    tools: ["Python", "SciPy", "SQL"],
    pathUrl: "https://github.com/Tariqve/portfolio-optimizer",
    imageUrl: "https://images.unsplash.com/photo-1435575653489-b0873ec954e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fGZpbmFuY2V8ZW58MHx8MHx8fDA%3D",
    category: "Quantitative Finance",
    featured: false
  },
  {
    id: "genomic-analysis",
    title: "Platea Analysis Data Genomicae",
    description: "Platea ad analysandum data genomica utens machina discendi et methodis statisticis ad indicia morborum detegenda.",
    tools: ["Python", "Biopython", "Spark"],
    pathUrl: "https://github.com/Tariqve/genomic-analysis",
    imageUrl: "https://images.unsplash.com/photo-1579154392429-0e6b4e850ad2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Z2VuZXN8ZW58MHx8MHx8fDA%3D",
    category: "Drug Discovery",
    featured: false
  },
  // Additional Projects in Latin:
  {
    id: "blockchain-voting",
    title: "Systema Suffragii Fundatum in Blockchain",
    description: "Platea suffragii tuta quae technologia blockchain ad transparentiam et integritatem assecurandam utitur.",
    tools: ["Blockchain", "Solidity", "Node.js"],
    pathUrl: "https://github.com/Tariqve/blockchain-voting",
    imageUrl: "https://images.unsplash.com/photo-1556740738-b6a63e27c4df?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Fintech",
    featured: false
  },
  {
    id: "energy-management",
    title: "Administratio Energiae Intelligens",
    description: "Systema intelligente ad optimizandum consumptionem energiae in retibus intelligentibus utens algorithmis AI.",
    tools: ["Python", "TensorFlow", "IoT"],
    pathUrl: "https://github.com/Tariqve/energy-management",
    imageUrl: "https://images.unsplash.com/photo-1581093588401-3e67f1bb8d89?auto=format&fit=crop&w=500&h=300&q=50",
    category: "Engineering",
    featured: false
  }
];
