// src/utils/Greetings.tsx
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const greetings = [
  "Salut",      // Romanian
  "مرحبا",     // Arabic
  "你好",       // Chinese
  "Hallo",      // German
  "Hello",      // English
  "Γειά σου",  // Greek
  "Bonjour",   // French (extra)
  "Hola",      // Spanish (extra)
  "こんにちは", // Japanese (extra)
  "Ciao"       // Italian (extra)
];

export function GreetingRotator() {
  // Determine initial greeting based on browser language
  const detectInitialGreetingIndex = (): number => {
    if (navigator.language) {
      const lang = navigator.language.toLowerCase();
      if (lang.startsWith("ro")) return 0;
      if (lang.startsWith("ar")) return 1;
      if (lang.startsWith("zh")) return 2;
      if (lang.startsWith("de")) return 3;
      if (lang.startsWith("el")) return 5;
    }
    return 4; // Default to English
  };

  const [currentGreetingIndex, setCurrentGreetingIndex] = useState(detectInitialGreetingIndex());
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(150);

  useEffect(() => {
    const fullText = greetings[currentGreetingIndex];
    let timer: number;

    if (!isDeleting && displayText === fullText) {
      timer = window.setTimeout(() => {
        setIsDeleting(true);
        setSpeed(100);
      }, 1500);
    } else if (isDeleting && displayText === '') {
      setIsDeleting(false);
      setCurrentGreetingIndex((prev) => (prev + 1) % greetings.length);
      setSpeed(150);
    } else {
      timer = window.setTimeout(() => {
        setDisplayText((prev) =>
          isDeleting
            ? fullText.substring(0, prev.length - 1)
            : fullText.substring(0, prev.length + 1)
        );
      }, speed);
    }
    return () => window.clearTimeout(timer);
  }, [displayText, isDeleting, currentGreetingIndex, speed]);

  return (
    <div className="flex items-center">
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="text-6xl sm:text-7xl font-semibold text-gradient min-h-[80px]"
      >
        {displayText}
      </motion.h1>
      <span className="ml-2 text-6xl sm:text-7xl font-thin text-gray-900 dark:text-gray-50 cursor-thin">|</span>
    </div>
  );
}
