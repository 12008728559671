import { motion } from 'framer-motion';
    import { ArrowLeft, ExternalLink, Search } from 'lucide-react';
    import { publications } from '../../data/publications';
    import { Link, useLocation } from 'react-router-dom';
    import { useEffect, useRef, useState } from 'react';
import { SectionHeader } from '../../utils/SectionHeader';

    export function Research() {
      const location = useLocation();
      const projectsRef = useRef<HTMLDivElement>(null);
      const publicationsRef = useRef<HTMLDivElement>(null);
      const [searchQuery, setSearchQuery] = useState('');
      const [searchExpanded, setSearchExpanded] = useState(false);

      useEffect(() => {
        if (location.hash === '#projects' && projectsRef.current) {
          projectsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (location.hash === '#publications' && publicationsRef.current) {
          publicationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else {
          window.scrollTo(0, 0);
        }
      }, [location.hash]);

      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
      };

      const filteredPublications = publications.filter(publication =>
        publication.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        publication.abstract.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return (
        <>
          <section className="relative py-10 overflow-hidden">
            <div className="absolute inset-0 bg-grid-pattern opacity-5" />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-900/10 to-blue-900/10" />
            
            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <SectionHeader 
                title='Research Area'   
              />

              {/* Publications Section */}
              <div id="publications" ref={publicationsRef}>
                <div className="flex justify-between items-center mb-6">
                <h3 className="text-3xl font-bold">
                  Publications
                </h3>
                  <motion.div
                    className="relative"
                    initial={{ width: 40 }}
                    animate={{ width: searchExpanded ? 300 : 50 }}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                  >
                    {searchExpanded ? (
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search publications..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          onBlur={() => setSearchExpanded(false)}
                          autoFocus
                          className="w-full px-4 py-2 h-10 pl-10 rounded-full focus:ring-1"
                        />
                        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                          <Search className="w-5 h-5" />
                        </div>
                      </div>
                    ) : (
                      <motion.button
                        onClick={() => setSearchExpanded(true)}
                        whileTap={{ scale: 0.96 }}
                        className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-800"
                      >
                        <Search className="w-5 h-5 " />
                      </motion.button>
                    )}
                  </motion.div>
            </div>

                <div className="space-y-4">
                  {filteredPublications.map((publication, index) => (
                    <motion.div
                      key={publication.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="bg-publication dark:bg-gray-800 rounded-md p-6 hover:shadow-sm transition-shadow"
                    >
                      <div className="space-y-1.5">
                        <div className="space-y-1">
                          <h3 className="text-xl font-bold">
                            {publication.title}
                          </h3>
                          {/* <p className="text-sm text-purple-600 dark:text-purple-400">
                            {publication.authors.join(', ')}
                          </p> */}
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            {publication.journal} • {publication.date}
                          </p>
                        </div>
                        <p className="leading-relaxed">
                          {publication.abstract}
                        </p>
                        <a
                          href={publication.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center space-x-2 accent-text"
                        >
                          <span>Read more</span>
                          <ExternalLink className="w-4 h-4" />
                        </a>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
              <div className="mt-12">
                <Link
                  to="/"
                  className="group flex items-center space-x-2 accent-text"
                >
                  <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
                  <span>Go Back to Homepage</span>
                </Link>
              </div>
            </div>
          </section>
        </>
      );
    }
