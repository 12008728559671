import { useEffect } from 'react';
import { Banner } from '../components/Banner';
import { FeaturedWorks } from '../components/FeaturedWorks';
import { RecentNotes } from '../components/RecentNotes';
import { Subscribe } from '../components/Subscribe';
import { ViewAllNotesLink } from '../components/ViewAllNotesLink';
// import { motion, AnimatePresence } from 'framer-motion';
// import { FeaturedProjects } from '../components/FeaturedProjects';
// import { FeaturedPublications } from '../components/FeaturedPublications';

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner />
      <FeaturedWorks />
      {/* <FeaturedProjects /> */}
      {/* <FeaturedPublications /> */}
      <div className='dark:bg-[#090909]'>
      <RecentNotes />
      <ViewAllNotesLink />
      <Subscribe />
      </div>
    </>
  );
}
