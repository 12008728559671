import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

export const ViewAllNotesLink: React.FC = () => {
  const location = useLocation();
  const toLink = location.pathname === '/notes' ? '/archive' : '/notes';

  return (
    <div className="mb-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="flex justify-end md:justify-start mx-10"
      >
        <Link
          to={toLink}
          className="inline-flex items-center justify-center px-10 py-2 border border-gray-600 rounded-full bg-transparent transition-colors hover:bg-black hover:text-white hover:border-black dark:hover:bg-white dark:hover:text-black dark:hover:border-black"
        >
          Browse All Notes
          <ChevronRight className="w-4 h-4 ml-1" />
        </Link>
      </motion.div>
    </div>
  );
};
