import { useState } from 'react';
import { Mail, Check, Loader } from 'lucide-react';
import { motion } from 'framer-motion';

const userProfile = {
  name: "Tarique",
  image:
    "https://static.vecteezy.com/system/resources/previews/036/294/022/original/cute-cat-black-and-white-cartoon-character-design-collection-white-background-pets-animals-vector.jpg",
};

type Status = null | 'submitting' | 'success' | 'error';

export function Subscribe() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<Status>(null);
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !agreed) return;

    try {
      setStatus('submitting');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setEmail('');
      setStatus('success');
      setTimeout(() => setStatus(null), 3000);
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className="sm:p-12">
      <div className="px-8 sm:px-14 py-20 sm:py-32 pt-16 sm:pt-28 rounded-xl max-w-2xl mx-auto shadow-lg flex flex-col items-center text-center bg-[var(--secondary-bg)]">
        <img 
          src={userProfile.image} 
          alt="Profile" 
          className="w-32 h-32 rounded-full mb-4" 
        />
        <h3 className="text-2xl font-semibold primary-text">
          {userProfile.name}'s Newsletter
        </h3>
        <p className="text-sm secondary-text max-w-md mt-2">
          Subscribe to stay updated with my latest blog notes, insights, and exclusive content.
        </p>

        <motion.form
          onSubmit={handleSubmit}
          className="w-full mt-6 space-y-4 hidden sm:block"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative w-full">
            <motion.input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-5 py-4 rounded-full bg-[var(--publication-bg)] border border-[var(--border-color)] primary-text focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              disabled={status === 'submitting'}
              required
              whileFocus={{ scale: 1.02 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
            <motion.button
              type="submit"
              disabled={status === 'submitting'}
              className="absolute right-1 top-1 bottom-1 px-5 bg-[var(--primary-text)] text-[var(--background-color)] dark:bg-[var(--primary-text)] dark:text-[var(--background-color)] rounded-full flex items-center justify-center transition-colors disabled:bg-gray-500"
              whileTap={{ scale: 0.98 }}
            >
              {status === 'submitting' ? (
                <Loader className="w-5 h-5 animate-spin" />
              ) : status === 'success' ? (
                <Check className="w-5 h-5" />
              ) : (
                <>
                  <span className="mr-2 hidden sm:inline">Subscribe</span>
                  <Mail className="w-5 h-5" />
                </>
              )}
            </motion.button>
          </div>

          {status === 'error' && (
            <p className="text-sm text-red-600 dark:text-red-400">
              Something went wrong. Please try again.
            </p>
          )}
          {status === 'success' && (
            <p className="text-sm text-green-600 dark:text-green-400 flex items-center justify-center">
              <Check className="w-4 h-4 mr-1" /> Thanks for subscribing!
            </p>
          )}
        </motion.form>

        <motion.form
          onSubmit={handleSubmit}
          className="w-full mt-6 space-y-4 sm:hidden"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full px-5 py-4 rounded-full bg-[var(--publication-bg)] border border-[var(--border-color)] primary-text focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            disabled={status === 'submitting'}
            required
            whileFocus={{ scale: 1.02 }}
            transition={{ type: 'spring', stiffness: 300 }}
          />
          <motion.button
            type="submit"
            disabled={status === 'submitting'}
            className="w-full px-5 py-4 bg-[var(--primary-text)] text-[var(--background-color)] dark:bg-[var(--primary-text)] dark:text-[var(--background-color)] rounded-full flex items-center justify-center transition-colors disabled:bg-gray-500"
            whileTap={{ scale: 0.98 }}
          >
            {status === 'submitting' ? (
              <Loader className="w-5 h-5 animate-spin" />
            ) : status === 'success' ? (
              <Check className="w-5 h-5" />
            ) : (
              <>
                <span className="mr-2">Subscribe</span>
                <Mail className="w-5 h-5" />
              </>
            )}
          </motion.button>

          {status === 'error' && (
            <p className="text-sm text-red-600 dark:text-red-400">
              Something went wrong. Please try again.
            </p>
          )}
          {status === 'success' && (
            <p className="text-sm text-green-600 dark:text-green-400 flex items-center justify-center">
              <Check className="w-4 h-4 mr-1" /> Thanks for subscribing!
            </p>
          )}
        </motion.form>

        <p className="text-xs text-gray-500 dark:text-gray-400 mt-4">
          We respect your privacy. Unsubscribe anytime.
        </p>
      </div>
    </div>
  );
}
