import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Sheepfold: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const artboard = canvasRef.current;
    // --- Basic Setup ---
    const pi = Math.PI;
    const scene = new THREE.Scene();
    const h = window.innerHeight;
    const w = window.innerWidth;
    const aspectRatio = w / h;
    const fieldOfView = 45;
    const nearPlane = 1;
    const farPlane = 1000;
    const camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);

    // Pass our canvas ref to the renderer so it renders into our component
    const renderer = new THREE.WebGLRenderer({
      canvas: artboard,
      alpha: true,
      antialias: true,
    });

    const dpi = window.devicePixelRatio;
    renderer.setSize(w * dpi, h * dpi);
    artboard.style.width = `${w}px`;
    artboard.style.height = `${h}px`;

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // --- Camera ---
    camera.position.set(25, 5, 0);
    camera.lookAt(new THREE.Vector3(0, 4, 0));

    // --- Lighting ---
    const col_light = 0xffffff;
    const light = new THREE.AmbientLight(col_light, 0.6);

    const keyLight = new THREE.DirectionalLight(col_light, 0.6);
    keyLight.position.set(20, 30, 10);
    keyLight.castShadow = true;
    keyLight.shadow.camera.top = 20;

    const fillLight = new THREE.DirectionalLight(col_light, 0.3);
    fillLight.position.set(-20, 20, 20);

    const backLight = new THREE.DirectionalLight(col_light, 0.1);
    backLight.position.set(10, 0, -20);

    scene.add(light, keyLight, fillLight, backLight);

    // --- Materials ---
    const mat_orange = new THREE.MeshLambertMaterial({ color: 0xff8c75 });
    const mat_grey = new THREE.MeshLambertMaterial({ color: 0xf3f2f7 });
    const mat_yellow = new THREE.MeshLambertMaterial({ color: 0xfeb42b });
    const mat_dark = new THREE.MeshLambertMaterial({ color: 0x5a6e6c });
    const mat_brown = new THREE.MeshLambertMaterial({ color: 0xa3785f });
    const mat_stone = new THREE.MeshLambertMaterial({ color: 0x9eaeac });

    // --- Ground ---
    const layers: THREE.Mesh[] = [];
    const ground = new THREE.Group();
    for (let i = 0; i < 5; i++) {
      const hLayer = 0.1;
      const geometry = new THREE.CylinderGeometry(8 - i - 0.01, 8 - i, hLayer, 9);
      const mesh = new THREE.Mesh(geometry, mat_orange);
      mesh.position.y = hLayer * i;
      mesh.receiveShadow = true;
      layers.push(mesh);
      ground.add(mesh);
    }
    layers[0].scale.x = 0.8;
    layers[1].scale.set(0.77, 1, 0.91);
    layers[1].rotation.y = ((2 * pi) / 9) * 0.6;
    layers[2].scale.set(0.8, 1, 0.91);
    layers[2].rotation.y = ((2 * pi) / 9) * 0.3;
    layers[3].scale.set(0.75, 1, 0.92);
    layers[3].rotation.y = ((2 * pi) / 9) * 0.7;
    layers[4].scale.set(0.7, 1, 0.93);
    layers[4].rotation.y = ((2 * pi) / 9) * 0.9;

    const geo_base = new THREE.CylinderGeometry(8, 1, 10, 9);
    const base = new THREE.Mesh(geo_base, mat_dark);
    base.scale.x = layers[0].scale.x;
    base.position.y = -5;
    ground.add(base);
    scene.add(ground);

    // --- Trees ---
    const tree = new THREE.Group();
    const geo_trunk = new THREE.IcosahedronGeometry(9, 0);
    const trunk = new THREE.Mesh(geo_trunk, mat_grey);
    trunk.rotation.x = pi / 2;
    trunk.position.y = 5;
    trunk.scale.set(0.03, 0.03, 1);
    trunk.castShadow = true;
    trunk.receiveShadow = true;
    tree.add(trunk);

    const geo_crown = new THREE.IcosahedronGeometry(2.5, 0);
    const crown = new THREE.Mesh(geo_crown, mat_yellow);
    crown.scale.y = 0.4;
    crown.rotation.z = -0.5;
    crown.rotation.x = -0.2;
    crown.position.set(trunk.position.x, 12, trunk.position.z);
    crown.castShadow = true;
    tree.add(crown);

    const leaf = new THREE.Group();
    const mainStem = new THREE.Mesh(geo_trunk, mat_grey);
    mainStem.scale.set(0.007, 0.007, 0.16);
    mainStem.rotation.x = pi / 2;
    mainStem.castShadow = true;
    leaf.add(mainStem);

    const geo_blade = new THREE.CylinderGeometry(0.7, 0.7, 0.05, 12);
    const blade = new THREE.Mesh(geo_blade, mat_yellow);
    blade.rotation.z = pi / 2;
    blade.scale.x = 1.2;
    blade.position.set(-0.05, 0.4, 0);
    blade.castShadow = true;
    leaf.add(blade);

    const subStems: THREE.Mesh[] = [];
    for (let i = 0; i < 8; i++) {
      const stem = mainStem.clone();
      stem.scale.set(0.0055, 0.0055, 0.01);
      stem.castShadow = true;
      subStems.push(stem);
      leaf.add(stem);
    }
    subStems[0].rotation.x = -pi / 4;
    subStems[0].scale.z = 0.04;
    subStems[0].position.set(0, 0.8, 0.2);

    subStems[2].rotation.x = -pi / 6;
    subStems[2].scale.z = 0.05;
    subStems[2].position.set(0, 0.5, 0.25);

    subStems[4].rotation.x = -pi / 8;
    subStems[4].scale.z = 0.055;
    subStems[4].position.set(0, 0.2, 0.3);

    subStems[6].rotation.x = -pi / 10;
    subStems[6].scale.z = 0.045;
    subStems[6].position.set(0, -0.1, 0.26);

    for (let i = 1; i < 8; i += 2) {
      subStems[i].rotation.x = -subStems[i - 1].rotation.x;
      subStems[i].scale.z = subStems[i - 1].scale.z;
      subStems[i].position.set(
        0,
        subStems[i - 1].position.y,
        -subStems[i - 1].position.z
      );
    }
    leaf.rotation.x = pi / 3;
    leaf.rotation.z = 0.2;
    leaf.position.set(trunk.position.x - 0.2, 5, trunk.position.z + 1);
    tree.add(leaf);

    const leaf_1 = leaf.clone();
    leaf_1.rotation.x = -pi / 3;
    leaf_1.position.set(trunk.position.x - 0.2, 6, trunk.position.z - 1);
    tree.add(leaf_1);
    tree.rotation.y = -pi / 12;
    tree.position.set(-2, 0, -2);
    scene.add(tree);

    const tree_1 = tree.clone();
    tree_1.scale.set(0.8, 0.8, 0.8);
    tree_1.position.set(-1, 0, -5);
    tree_1.rotation.y = -pi / 5;
    scene.add(tree_1);

    const tree_2 = tree.clone();
    tree_2.scale.set(0.7, 0.7, 0.7);
    tree_2.position.set(-2, 0, 0.5);
    tree_2.rotation.y = -pi / 12;
    if (tree_2.children[2]) {
      tree_2.children[2].rotation.x = -pi / 3;
      tree_2.children[2].position.z = trunk.position.z - 1;
    }
    if (tree_2.children[3]) {
      tree_2.children[3].rotation.x = pi / 3;
      tree_2.children[3].position.z = trunk.position.z + 1;
    }
    scene.add(tree_2);

    // --- Stone ---
    const geo_stone = new THREE.DodecahedronGeometry(1, 0);
    const stone: THREE.Mesh[] = [];
    for (let i = 0; i < 2; i++) {
      const s = new THREE.Mesh(geo_stone, mat_stone);
      s.castShadow = true;
      stone.push(s);
      scene.add(s);
    }
    stone[0].rotation.set(0, 12, pi / 2);
    stone[0].scale.set(3, 1, 1);
    stone[0].position.set(-1, 1, 4.6);

    stone[1].rotation.set(0, 0, pi / 2);
    stone[1].scale.set(1, 1, 1);
    stone[1].position.set(0, 0.7, 5.3);

    // --- Sheep ---
    const sheep = new THREE.Group();
    const geo_sheepHead = new THREE.IcosahedronGeometry(1, 0);
    const sheepHead = new THREE.Mesh(geo_sheepHead, mat_dark);
    sheepHead.scale.z = 0.6;
    sheepHead.scale.y = 1.1;
    sheepHead.position.y = 2.5;
    sheepHead.rotation.x = -0.2;
    sheepHead.castShadow = true;
    sheep.add(sheepHead);

    const geo_sheepBody = new THREE.IcosahedronGeometry(3.5, 0);
    const sheepBody = new THREE.Mesh(geo_sheepBody, mat_grey);
    sheepBody.position.set(0, sheepHead.position.y, -2.2);
    sheepBody.scale.set(0.5, 0.5, 0.6);
    sheepBody.rotation.set(0, 0, pi / 3);
    sheepBody.castShadow = true;
    sheep.add(sheepBody);

    const geo_tail = new THREE.IcosahedronGeometry(0.5, 0);
    const tail = new THREE.Mesh(geo_tail, mat_grey);
    tail.position.set(sheepHead.position.x, sheepHead.position.y + 1.2, -3.8);
    tail.castShadow = true;
    sheep.add(tail);

    const hair: THREE.Mesh[] = [];
    const geo_hair = new THREE.IcosahedronGeometry(0.4, 0);
    for (let i = 0; i < 5; i++) {
      const hMesh = new THREE.Mesh(geo_hair, mat_grey);
      hMesh.castShadow = true;
      hair.push(hMesh);
      sheep.add(hMesh);
    }
    hair[0].position.set(-0.4, sheepHead.position.y + 0.9, -0.1);
    hair[1].position.set(0, sheepHead.position.y + 1, -0.1);
    hair[2].position.set(0.4, sheepHead.position.y + 0.9, -0.1);
    hair[3].position.set(-0.1, sheepHead.position.y + 0.9, -0.4);
    hair[4].position.set(0.12, sheepHead.position.y + 0.9, -0.4);

    hair[0].rotation.set(pi / 12, 0, pi / 3);
    hair[1].rotation.set(pi / 12, pi / 6, pi / 3);
    hair[2].rotation.set(pi / 12, 0, pi / 3);
    hair[3].rotation.set(pi / 12, 0, pi / 3);
    hair[4].rotation.set(pi / 12, pi / 6, pi / 3);

    hair[0].scale.set(0.6, 0.6, 0.6);
    hair[2].scale.set(0.8, 0.8, 0.8);
    hair[3].scale.set(0.7, 0.7, 0.7);
    hair[4].scale.set(0.6, 0.6, 0.6);

    const legs: THREE.Mesh[] = [];
    const geo_leg = new THREE.CylinderGeometry(0.15, 0.1, 1, 5);
    for (let i = 0; i < 4; i++) {
      const leg = new THREE.Mesh(geo_leg, mat_dark);
      leg.castShadow = true;
      leg.receiveShadow = true;
      legs.push(leg);
      sheep.add(leg);
    }
    legs[0].position.set(0.5, 1.1, -1.5);
    legs[1].position.set(-0.5, 1.1, -1.5);
    legs[2].position.set(0.8, 1.1, -3);
    legs[3].position.set(-0.8, 1.1, -3);

    const feet: THREE.Mesh[] = [];
    const geo_foot = new THREE.DodecahedronGeometry(0.2, 0);
    for (let i = 0; i < legs.length; i++) {
      const foot = new THREE.Mesh(geo_foot, mat_dark);
      foot.scale.set(1, 0.8, 1);
      foot.castShadow = true;
      foot.receiveShadow = true;
      foot.position.set(legs[i].position.x, 0, legs[i].position.z + 0.09);
      feet.push(foot);
      sheep.add(foot);
    }
    feet[0].position.y = 0.56;
    feet[1].position.y = 0.66;
    feet[2].position.y = 0.7;
    feet[3].position.y = 0.7;

    const geo_eye = new THREE.CylinderGeometry(0.3, 0.2, 0.05, 8);
    const eyes: THREE.Mesh[] = [];
    for (let i = 0; i < 2; i++) {
      const eye = new THREE.Mesh(geo_eye, mat_grey);
      eye.castShadow = true;
      eye.position.set(0, sheepHead.position.y + 0.1, 0.5);
      eye.rotation.x = pi / 2 - pi / 15;
      eyes.push(eye);
      sheep.add(eye);
    }
    eyes[0].position.x = 0.3;
    eyes[1].position.x = -0.3;
    eyes[0].rotation.z = -pi / 15;
    eyes[1].rotation.z = -pi / 15;

    const eyeballs: THREE.Mesh[] = [];
    const geo_eyeball = new THREE.SphereGeometry(0.11, 8, 8);
    for (let i = 0; i < 2; i++) {
      const eyeball = new THREE.Mesh(geo_eyeball, mat_dark);
      eyeball.castShadow = true;
      eyeball.position.set(
        eyes[i].position.x,
        eyes[i].position.y,
        eyes[i].position.z + 0.02
      );
      eyeballs.push(eyeball);
      sheep.add(eyeball);
    }
    sheep.position.set(4.8, -0.2, -1);
    sheep.scale.set(0.8, 0.8, 0.8);
    sheep.rotation.set(0, pi / 4, 0);
    scene.add(sheep);

    // --- Fence ---
    const fence = new THREE.Group();
    const wood: THREE.Mesh[] = [];
    const geo_wood = new THREE.BoxGeometry(1, 1, 1);
    for (let i = 0; i < 4; i++) {
      const wMesh = new THREE.Mesh(geo_wood, mat_brown);
      wMesh.castShadow = true;
      wMesh.receiveShadow = true;
      wood.push(wMesh);
      fence.add(wMesh);
    }
    wood[0].scale.set(0.15, 1.7, 0.4);
    wood[1].scale.set(0.15, 1.8, 0.4);
    wood[2].scale.set(0.1, 0.3, 3.2);
    wood[3].scale.set(0.1, 0.3, 3.2);

    wood[0].position.set(0, 1.2, -1);
    wood[1].position.set(0, 1, 1);
    wood[2].position.set(0, 1.5, 0);
    wood[3].position.set(0.12, 0.9, 0);

    wood[3].rotation.x = pi / 32;
    wood[2].rotation.x = -pi / 32;
    wood[2].rotation.y = pi / 32;

    fence.position.set(3, 0, 2);
    fence.rotation.y = pi / 5;
    scene.add(fence);

    // --- Render Loop ---
    const renderLoop = () => {
      requestAnimationFrame(renderLoop);
      renderer.render(scene, camera);
    };
    renderLoop();

    // --- Mouse Control for Eyeballs ---
    const mouseMoveHandler = (evt: MouseEvent) => {
      const rect = artboard.getBoundingClientRect();
      const mouseX = evt.clientX - rect.left;
      const mouseY = evt.clientY - rect.top;
      const offsetX = (0.2 / rect.width) * (mouseX - rect.width / 2);
      const offsetY = (0.3 / rect.height) * (mouseY - (rect.height * 2) / 5);
      if (eyeballs[0] && eyes[0]) {
        eyeballs[0].position.x = eyes[0].position.x + offsetX;
        eyeballs[0].position.y = eyes[0].position.y - offsetY;
      }
      if (eyeballs[1] && eyes[1]) {
        eyeballs[1].position.x = eyes[1].position.x + offsetX;
        eyeballs[1].position.y = eyes[1].position.y - offsetY;
      }
    };
    artboard.addEventListener('mousemove', mouseMoveHandler);

    // Cleanup on unmount
    return () => {
      artboard.removeEventListener('mousemove', mouseMoveHandler);
      // Dispose of objects if needed
    };
  }, []);

  return (
    <div className="flex justify-center items-center overflow-hidden">
      <canvas id="artboard" ref={canvasRef}></canvas>
    </div>
  );
};

export default Sheepfold;
