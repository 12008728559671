import { useState } from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "../hooks/useTheme";
import { GiGreekTemple } from "react-icons/gi";
import { motion } from "framer-motion";
import { MdGrid3X3 } from "react-icons/md";
import { RiSnowflakeLine } from "react-icons/ri";
import { FiCpu } from "react-icons/fi";
import { LiaCanadianMapleLeaf } from "react-icons/lia";
import { TiLeaf } from "react-icons/ti";
import { Tooltip } from "./Tooltip";

// Define Theme Icons Structure
interface ThemeIcon {
  theme: string;
  icon: JSX.Element;
  name: string;
}

// ThemeToggle Component
export function ThemeToggle() {
  const { theme, toggleTheme } = useTheme();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const icons: ThemeIcon[] = [
    { theme: "light", icon: <Sun className="w-5 h-5" strokeWidth="2" />, name: "Light" },
    { theme: "dark", icon: <Moon className="w-5 h-5" strokeWidth="2" />, name: "Dark" },
    { theme: "vintage", icon: <GiGreekTemple className="w-5 h-5" style={{ strokeWidth: "3" }} />, name: "Vintage" },
    { theme: "autumn", icon: <LiaCanadianMapleLeaf className="w-5 h-5" style={{ strokeWidth: "1" }} />, name: "Autumn" },
    { theme: "nature", icon: <TiLeaf className="w-5 h-5" style={{ strokeWidth: "1" }} />, name: "Evergreen" },
    { theme: "future", icon: <FiCpu className="w-5 h-5" style={{ strokeWidth: "2" }} />, name: "Future" },
    { theme: "winter", icon: <RiSnowflakeLine className="w-5 h-5" style={{ strokeWidth: "1" }} />, name: "Winter" },
    { theme: "retro", icon: <MdGrid3X3 className="w-5 h-5" />, name: "Retrowave" },
  ];

  // Find the icon for the current theme
  const currentIcon = icons.find((icon) => icon.theme === theme);

  return (
    <motion.button
      onClick={toggleTheme}
      className="p-2 relative"
      aria-label="Toggle theme"
      whileHover={{ scale: 0.96 }}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {currentIcon && (
        <Tooltip content={currentIcon.name} isVisible={tooltipVisible}>
          {currentIcon.icon}
        </Tooltip>
      )}
    </motion.button>
  );
}
