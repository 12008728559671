import { RiTelegram2Fill, RiGithubFill, RiLinkedinFill } from "react-icons/ri";

export function Footer() {
  const socialLinks = [
    {
      icon: RiGithubFill,
      href: 'https://github.com/Tariqve',
      label: 'GitHub',
    },
    {
      icon: RiTelegram2Fill,
      href: 'https://t.me/Tariqve',
      label: 'Telegram',
    },
    {
      icon: RiLinkedinFill,
      href: 'https://linkedin.com/in/Tariqve',
      label: 'LinkedIn',
    },
  ];
  return (
    <footer className="bg-[#121212] p-4 pt-8 md:p-6 md:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div className="col-span-2">
            <h3 className="text-xl font-bold text-gray-100 mb-4">
              Tarique  
            </h3>
            <p className="text-gray-300 mb-4">
              Thanks for visiting by.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-100 mb-4">
              Quick Links
            </h4>
            <ul className="space-y-2">
              <li>
                <a href="/articles" className="text-gray-300 hover:text-purple-400">
                  Articles
                </a>
              </li>
              <li>
                <a href="/library" className="text-gray-300 hover:text-purple-400">
                  Library
                </a>
              </li>
              <li>
                <a href="/projects" className="text-gray-300 hover:text-purple-400">
                  Projects
                </a>
              </li>
              <li>
                <a href="/publications" className="text-gray-300 hover:text-purple-400">
                  Publications
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex space-x-4">
              {socialLinks.map((link) => (
                <li key={link.label}>
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-300 hover:text-purple-400"
                  >
                    <link.icon className="w-6 h-6" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-10 pt-4 md:mt-20 md:pt-6 border-t border-gray-800 text-center">
          <p className="text-gray-400 text-sm">
            © {new Date().getFullYear()} Han's Labs. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
