export interface Publication {
  id: string;
  title: string;
  authors: string[];
  journal: string;
  date: string;
  abstract: string;
  url: string;
  featured?: boolean;
}

export const publications: Publication[] = [
  {
    id: "quantum-finance",
    title: "Applicationes Computandi Quantici in Mercatura Alta Frequentia",
    authors: ["Tarique V.", "Smith, J.", "Johnson, R."],
    journal: "Journal of Quantum Finance",
    date: "2024",
    abstract: "Hoc opus novum accessum ad mercaturam altae frequentiae per algorithmos computandi quantici proponit, ostendens meliorationes notabiles in celeritate executionis et accurate.",
    url: "https://doi.org/10.1234/qf.2024.01",
    featured: true
  },
  {
    id: "neural-search",
    title: "Quaestio Efficax Architecturae Neuralis Usus Discendi Refectionis",
    authors: ["Tarique V.", "Anderson, M."],
    journal: "Conference on Neural Information Processing Systems",
    date: "2023",
    abstract: "Novam methodum quaestionis architecturae neuralis proponimus quae requisita computatralia note minuit, dum statum artis praestans conservat.",
    url: "https://doi.org/10.1234/neurips.2023.02",
    featured: true
  },
  {
    id: "sustainable-ai",
    title: "Urbanistica Artificiosa A.I. ad Urbes Sustentabiles",
    authors: ["Tarique V.", "Zhang, L.", "Patel, S."],
    journal: "Sustainable Computing Journal",
    date: "2023",
    abstract: "Haec investigatio structuram A.I. ad optandum consilium urbanisticum introducit, intendens in mensuris sustinibilitatis et indicatoribus qualitatis vitae.",
    url: "https://doi.org/10.1234/sust.2023.03",
    featured: true
  },
  {
    id: "ai-ethics",
    title: "Considerationes Ethicae in Evolutione A.I.",
    authors: ["Tarique V.", "Brown, A.", "Davis, K."],
    journal: "Journal of Artificial Intelligence Ethics",
    date: "2024",
    abstract: "Hoc opus implicationes ethicas evolutionis A.I. explorat et praecepta pro praxibus A.I. responsalibus proponit.",
    url: "https://doi.org/10.1234/aieth.2024.04",
    featured: false
  },
  {
    id: "nlp-healthcare",
    title: "Processus Linguae Naturalis pro Applicationibus Salutis",
    authors: ["Tarique V.", "Wilson, E.", "Garcia, R."],
    journal: "International Journal of Medical Informatics",
    date: "2023",
    abstract: "Haec investigatio usum technicarum NLP ad meliorandum exitus sanitatis investigat, inter diagnosis aegris et consilium curationis.",
    url: "https://doi.org/10.1234/medinf.2023.05",
    featured: false
  },
  {
    id: "quantum-cryptography",
    title: "Cryptographia Quantica pro Communicatione Securata",
    authors: ["Tarique V.", "Lee, H.", "Kim, S."],
    journal: "Journal of Quantum Information Processing",
    date: "2024",
    abstract: "Hoc opus novum accessum ad communicationem securatam per protocolla cryptographiae quantica proponit, securitatem a minacibus cyberneticis augens.",
    url: "https://doi.org/10.1234/qip.2024.06",
    featured: false
  },
  {
    id: "computer-vision-robotics",
    title: "Visio Computatralis pro Robotica Autonoma",
    authors: ["Tarique V.", "Nguyen, T.", "Chen, L."],
    journal: "International Journal of Robotics Research",
    date: "2023",
    abstract: "Haec investigatio usum technicarum visionis computatralis explorat ad navigationem autonomam et agnitionem obiectorum in applicationibus roboticis efficiendam.",
    url: "https://doi.org/10.1234/robotics.2023.07",
    featured: false
  },
  {
    id: "big-data-analytics",
    title: "Analytica Magna Data pro Intelligentia Negotiationis",
    authors: ["Tarique V.", "Rodriguez, M.", "Perez, J."],
    journal: "Journal of Business Analytics",
    date: "2024",
    abstract: "Hoc opus usum analyticorum magnis data ad intelligentiam negotii et processus decisionis meliorandos investigat.",
    url: "https://doi.org/10.1234/bizanal.2024.08",
    featured: false
  },
  // Additional Publications in Latin:
  {
    id: "ml-security",
    title: "Technicae Discendi Machinae pro Augmento Cybersecuritatis",
    authors: ["Tarique V.", "Miller, D.", "Garcia, F."],
    journal: "Journal Rerum Cybersecuritatis",
    date: "2024",
    abstract: "Hoc opus examinat quomodo technicae discendi machinae ad munera cybersecuritatis augendae adhiberi possint, solutiones innovativas minacibus evolventibus praebens.",
    url: "https://doi.org/10.1234/mlsec.2024.09",
    featured: false
  },
  {
    id: "data-privacy",
    title: "Secretum Data in Aetate Transformationis Digitalis",
    authors: ["Tarique V.", "O'Connor, E."],
    journal: "Journal Internationalis Securitatis Informationis",
    date: "2023",
    abstract: "Hoc opus strategias ad tuendum data personalia inter digitalizationem crescentem explorat, regulas et technicas accessus tractans.",
    url: "https://doi.org/10.1234/datapriv.2023.10",
    featured: false
  }
];
