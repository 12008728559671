// ../data/notes.ts

// Define the Category type
export interface Category {
    id: string;
    name: string;
    description?: string;
  }
  
  // Define the Note type
  export interface Note {
    id: string;
    title: string;
    excerpt: string;
    date: string; // Store as ISO string for easy parsing
    image: string;
    banner?: string; // Optional banner property
    categories: string[];
    readTime: number;
    featured: boolean;
    author: {
      name: string;
      avatar?: string;
    };
  }
  
  // Categories data
  export const categories: Category[] = [
    {
      id: 'ai',
      name: 'Artificial Intelligence',
      description: 'Topics related to AI research, applications, and implications',
    },
    {
      id: 'ml',
      name: 'Machine Learning',
      description: 'Practical applications and theory of machine learning algorithms',
    },
    {
      id: 'quantum',
      name: 'Quantum Computing',
      description: 'Research and advancements in quantum computing',
    },
    {
      id: 'data-science',
      name: 'Data Science',
      description: 'Methods, tools, and approaches for working with data',
    },
    {
      id: 'research',
      name: 'Research',
      description: 'Academic research and publications',
    },
    {
      id: 'tutorials',
      name: 'Tutorials',
      description: 'Step-by-step guides and educational content',
    },
    {
      id: 'webdev',
      name: 'Web Development',
      description: 'Front-end, back-end, and full-stack web development topics.',
    },
    {
      id: 'mobiledev',
      name: 'Mobile Development',
      description: 'Developing applications for iOS and Android platforms.',
    },
    {
      id: 'cybersecurity',
      name: 'Cybersecurity',
      description: 'Protecting systems and networks from digital attacks.',
    },
  ];
  
  // Notes data with updated titles, images, and three banner properties
  export const notes: Note[] = [
    {
      id: 'quantum-ml-intersection',
      title: 'Intersecionem Quantica et Machinae Discendi',
      excerpt: 'Exploratio methodorum et applicationum quae inter se connectuntur in campo quantico et machinali discendo.',
      date: '2024-02-20',
      image: 'https://placehold.co/600x400/FFB6C1/323232?text=Intersecionem+Quantica+et+Discendi',
      banner: 'https://placehold.co/1200x400/FFB6C1/323232?text=Quantum+et+ML:+Perspectiva',
      categories: ['quantum', 'ml', 'research'],
      readTime: 12,
      featured: true,
      author: {
        name: 'Alex Chen',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'ai-ethics',
      title: 'Ethica Artificialis Intelligentiae',
      excerpt: 'Disputatio de principiis et applicationibus ethicae in systematibus intelligentiae artificialis.',
      date: '2024-02-18',
      image: 'https://placehold.co/600x400/B0E0E6/323232?text=Ethica+Artificialis+Intelligentiae',
      banner: 'https://placehold.co/1200x400/B0E0E6/323232?text=Ethica+AI:+Perspectiva',
      categories: ['ai', 'research'],
      readTime: 8,
      featured: false,
      author: {
        name: 'Dr. Emily Carter',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'data-science-python',
      title: 'Scientia Datae per Python',
      excerpt: 'Expositio de instrumentis et technicis ad explorandum et analysandum data in ambitu Python.',
      date: '2024-02-15',
      image: 'https://placehold.co/600x400/98FB98/323232?text=Scientia+Datae+per+Python',
      categories: ['data-science', 'tutorials', 'python'],
      readTime: 10,
      featured: true,
      author: {
        name: 'John Smith',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'react-tutorial',
      title: 'React: Disciplina Practica',
      excerpt: 'Tutorial pas a pas ad discendum et operandum cum React in applicationibus web.',
      date: '2024-02-12',
      image: 'https://placehold.co/600x400/FFDAB9/323232?text=React+Disciplina+Practica',
      categories: ['webdev', 'tutorials', 'react'],
      readTime: 15,
      featured: false,
      author: {
        name: 'Jane Doe',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'intro-to-ml',
      title: 'Introductio ad Machinam Discendi',
      excerpt: 'Conceptus fundamentalis et introductio ad machinam discendi et eius applicationes.',
      date: '2024-02-10',
      image: 'https://placehold.co/600x400/E6E6FA/323232?text=Introductio+ad+Machinam+Discendi',
      categories: ['ml', 'tutorials'],
      readTime: 9,
      featured: false,
      author: {
        name: 'David Lee',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'cybersecurity-fundamentals',
      title: 'Fundamenta Cybersecuritatis',
      excerpt: 'Analysis de principiis securitatis digitalis et modis tuendi systemata et retia.',
      date: '2024-02-08',
      image: 'https://placehold.co/600x400/FFFACD/323232?text=Fundamenta+Cybersecuritatis',
      categories: ['cybersecurity', 'tutorials'],
      readTime: 7,
      featured: false,
      author: {
        name: 'Sarah Williams',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'mobile-app-dev-flutter',
      title: 'Flutter: Evolutio App Mobilis',
      excerpt: 'Exploratio instrumentorum Flutter ad creanda applicationes mobilis cum interfaciei modernae sensu.',
      date: '2024-02-05',
      image: 'https://placehold.co/600x400/FFE4E1/323232?text=Flutter+Evolutio+App+Mobilis',
      categories: ['mobiledev', 'tutorials', 'flutter'],
      readTime: 14,
      featured: true,
      author: {
        name: 'Michael Brown',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'web-performance-optimization',
      title: 'Optimatio Perficientiae Web',
      excerpt: 'Methodi et technicae ad meliorem faciendam celeritatem et efficientiam paginarum web.',
      date: '2024-02-02',
      image: 'https://placehold.co/600x400/F0E68C/323232?text=Optimatio+Perficientiae+Web',
      categories: ['webdev', 'tutorials'],
      readTime: 11,
      featured: false,
      author: {
        name: 'Jessica White',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'advanced-ai-techniques',
      title: 'Technicae Avancatae Artificialis Intelligentiae',
      excerpt: 'Discussio de novissimis progressibus in technicis et algorithmis ad augendam intelligentiam artificialis.',
      date: '2024-01-28',
      image: 'https://placehold.co/600x400/AFEEEE/323232?text=Technicae+Avancatae+AI',
      categories: ['ai', 'research'],
      readTime: 14,
      featured: false,
      author: {
        name: 'Kevin Nguyen',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'data-visualization-d3',
      title: 'D3: Visualisatio Datae',
      excerpt: 'Instructio de instrumentis et technicis ad visualizandum data per D3 et alias bibliothecas.',
      date: '2024-01-25',
      image: 'https://placehold.co/600x400/FFEFD5/323232?text=D3+Visualisatio+Datae',
      categories: ['data-science', 'tutorials', 'webdev'],
      readTime: 12,
      featured: false,
      author: {
        name: 'Maria Rodriguez',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'blockchain-basics',
      title: 'Elementa Blockchain',
      excerpt: 'Expositio principiis fundamentalis et applicationibus blockchain in variis industriis.',
      date: '2024-01-22',
      image: 'https://placehold.co/600x400/F5DEB3/323232?text=Elementa+Blockchain',
      categories: ['blockchain', 'tutorials'],
      readTime: 10,
      featured: false,
      author: {
        name: 'Chris Lee',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'cloud-computing-intro',
      title: 'Introductio ad Computatio Nubilum',
      excerpt: 'Conceptus et methodi ad explorandum et operandum in computando nubis, cum accentu in scalabilitate.',
      date: '2024-01-19',
      image: 'https://placehold.co/600x400/FFF0F5/323232?text=Introductio+Computatio+Nubilum',
      categories: ['cloud', 'tutorials'],
      readTime: 8,
      featured: false,
      author: {
        name: 'Ashley Kim',
        avatar: '/api/placeholder/64/64',
      },
    },
    {
      id: 'devops-practices',
      title: 'Practicis DevOps: Nova Era',
      excerpt: 'Analysi modorum et consiliorum ad optimizandum processuum evolutionis software et deployment.',
      date: '2024-01-16',
      image: 'https://placehold.co/600x400/FAFAD2/323232?text=Practicis+DevOps+Nova+Era',
      banner: 'https://placehold.co/1200x400/FAFAD2/323232?text=DevOps:+Nova+Era',
      categories: ['devops', 'tutorials'],
      readTime: 12,
      featured: true,
      author: {
        name: 'John Doe',
        avatar: '/api/placeholder/64/64',
      },
    },
  ];
  