import Home from './pages/index.tsx';
  import About from './pages/about/index.tsx';
  import { Portfollio } from './pages/portfolio/index.tsx';
  import { Research } from './pages/research/index.tsx';
  
import { Navbar } from './layout/Navbar';
import { Footer } from './layout/Footer';

import { Routes, Route } from 'react-router-dom';
import Sheepfold from './utils/Sheepfold';
import WinterScene from './utils/WinterScene.tsx';

function App() {
  return (
    <div className="min-h-screen">
      <main className="relative">
      <Navbar />
        <Routes>
          <Route path="*" element={< Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfollio />} />
          <Route path="/research" element={<Research />} />
          <Route path="/sheepfold" element={<Sheepfold />} />
          <Route path="/winter" element={<WinterScene />} />
        </Routes>
        <Footer />
      </main>
    </div>
  );
}

export default App;
