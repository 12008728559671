// src/components/BannerArtifact.tsx

import React, { Suspense } from "react";
import { motion } from "framer-motion";
import Spline from "@splinetool/react-spline";
import Sheepfold from "../utils/Sheepfold";

const BannerArtifact: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 2, duration: 1 }}
      className="relative flex-1 inset-0 w-full md:h-[80vh] md:w-[80vh] pointer-events-none h-auto hidden md:flex md:scale-70 items-center overflow-visible justify-start z-30"
    >
      {/* Dark Banner Artifact */}
      <Suspense fallback={<div className="w-full h-full bg-transparent" />}>
        <Spline
          scene="https://prod.spline.design/NYl8mQFQFJfXbWTW/scene.splinecode"
          className="absolute hidden dark:block pointer-events-none inset-0 object-contain w-full h-full"
        />
      </Suspense>
      <div className="absolute hidden dark:block inset-0 bg-gradient-to-br from-purple-500/20 to-blue-500/20 rounded-full blur-3xl animate-pulse" />

      {/* Nature Banner Artifact */}
      <Suspense fallback={<div className="w-full h-full" />}>
        <div className="absolute hidden nature:block z-50 pointer-events-none inset-0 object-contain w-full h-full">
          <Sheepfold />
        </div>
      </Suspense>
      <div className="absolute hidden nature:block inset-0 bg-gradient-to-br from-green-500/20 to-yellow-300/20 rounded-full blur-3xl animate-pulse" />
    </motion.div>
  );
};

export default BannerArtifact;
