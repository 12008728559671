import { useState, useEffect, useRef } from 'react';
import { Mail, Check, Loader } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface SubscribePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const userProfile = {
  name: "Tarique",
  image:
    "https://static.vecteezy.com/system/resources/previews/036/294/022/original/cute-cat-black-and-white-cartoon-character-design-collection-white-background-pets-animals-vector.jpg",
};

type Status = null | "submitting" | "success" | "error";

export function SubscribePopup({ isOpen, onClose }: SubscribePopupProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<Status>(null);

  // Close the popup if clicking outside the modal.
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, onClose]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) return;

    try {
      setStatus("submitting");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setEmail("");
      setStatus("success");
      setTimeout(() => setStatus(null), 3000);
    } catch (error) {
      setStatus("error");
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 flex items-center justify-center 
          bg-white/70 
          dark:bg-[#111827]/80 
          vintage:bg-[#f8f4ea]/80 
          nature:bg-[#f1f8f5]/80 
          retro:bg-[#111827]/80 
          autumn:bg-[#3b2c20]/80 
          future:bg-[#111827]/80 
          winter:bg-[#f0f7ff]/80"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          ref={modalRef}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          className="rounded-xl p-16 max-w-lg w-full backdrop-blur-xl shadow-lg relative
            bg-transparent border border-[#d1d5db]
            dark:bg-[#141414]/30 dark:border-[#1f2937]
            vintage:bg-[#fffdf6]/30 vintage:border-[#d6c8b3]
            nature:bg-[#f9fefb]/30 nature:border-[#d1e8d4]
            retro:bg-[#2a273b]/30 retro:border-[#9d4edd]
            autumn:bg-[#4a392e]/30 autumn:border-[#5a3921]
            future:bg-[#141414]/30 future:border-[#1f2937]
            winter:bg-[#fafcff]/30 winter:border-[#d1e5f3]"
        >
          <div className="flex flex-col items-center text-center">
            <img
              src={userProfile.image}
              alt="Profile"
              className="w-32 h-32 rounded-full mb-4"
            />
            <h3 
              className="text-2xl font-semibold"
            >
              {userProfile.name}'s Newsletter
            </h3>
            <p 
              className="text-sm mt-2 max-w-md"
            >
              Subscribe to stay updated with my latest blog notes, insights, and exclusive content.
            </p>
          </div>
          <motion.form
            onSubmit={handleSubmit}
            className="w-full mt-8 space-y-4"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div>
              <motion.input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full px-5 py-4 rounded-full focus:outline-none focus:ring-2 focus:ring-[#7c3aed]"
                disabled={status === "submitting"}
                required
                whileFocus={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              />
            </div>
            <div>
              <motion.button
                type="submit"
                disabled={status === "submitting"}
                className="w-full px-5 py-4 rounded-full flex items-center justify-center transition-colors disabled:bg-gray-500"
                whileTap={{ scale: 0.98 }}
              >
                {status === "submitting" ? (
                  <Loader className="w-5 h-5 animate-spin" />
                ) : status === "success" ? (
                  <Check className="w-5 h-5" />
                ) : (
                  <>
                    <span className="mr-2">Subscribe</span>
                    <Mail className="w-5 h-5" />
                  </>
                )}
              </motion.button>
            </div>
            {status === "error" && (
              <p className="text-sm text-red-600 dark:text-red-400">
                Something went wrong. Please try again.
              </p>
            )}
            {status === "success" && (
              <p className="text-sm text-green-600 dark:text-green-400 flex items-center justify-center">
                <Check className="w-4 h-4 mr-1" /> Thanks for subscribing!
              </p>
            )}
          </motion.form>
          <p 
            className="text-xs mt-4 text-center"
          >
            We respect your privacy. Unsubscribe anytime.
          </p>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
