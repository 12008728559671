import { useState, useRef, useEffect } from 'react';
import { Search, Grid, List, ChevronRight } from 'lucide-react';
import { notes, categories } from '../data/notes';
import { motion, AnimatePresence } from 'framer-motion';
import { SubscribePopup } from './SubscribePopup';
import { SectionHeader } from '../utils/SectionHeader';

export function RecentNotes() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [activeTab, setActiveTab] = useState('recent');
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isTagsFixed, setIsTagsFixed] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const sectionRef = useRef<HTMLElement | null>(null);
  const tagsRef = useRef<HTMLDivElement | null>(null);

  const filteredNotes = notes
    .filter(note => {
      const matchesSearch =
        searchQuery === '' ||
        note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        note.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory =
        selectedCategory === 'all' || note.categories.includes(selectedCategory);
      const matchesTab = activeTab === 'recent' ? true : note.featured;
      return matchesSearch && matchesCategory && matchesTab;
    })
    .slice(0, activeTab === 'recent' ? 6 : 3);

  const listVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        ease: 'easeOut',
        duration: 0.5,
      },
    },
  };

  const listItemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.4, ease: 'easeOut' },
    },
  };

  useEffect(() => {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current && tagsRef.current) {
        const sectionRect = sectionRef.current.getBoundingClientRect();
        const tagsRect = tagsRef.current.getBoundingClientRect();
        const sectionTopInView = sectionRect.top <= window.innerHeight;
        const sectionBottomInView = sectionRect.bottom >= 0;
        if (sectionTopInView && sectionBottomInView) {
          const stickyOffset = 64 + 40;
          setIsTagsFixed(tagsRect.top <= stickyOffset);
        } else {
          setIsTagsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section
      ref={sectionRef as React.Ref<HTMLElement>}
      id="notes"
      className="py-10 relative max-w-7xl mx-auto px-4 sm:px-6"
    >
      <div className="max-w-7xl mx-auto">
        <SectionHeader title="Notes & Insights" />

        {/* Navbar */}
        <div className="flex items-center justify-between border-b border-color w-full">
          <div className="flex items-center">
            <motion.button
              onClick={() => setActiveTab('recent')}
              className={`px-4 py-1.5 font-medium relative ${
                activeTab === 'recent'
                  ? 'primary-text'
                  : 'secondary-text accent-hover'
              }`}
              whileTap={{ scale: 0.98 }}
            >
              Recent
              {activeTab === 'recent' && (
                <motion.span
                  layoutId="underline"
                  className="absolute left-0 bottom-0 h-1 secondary-bg w-full"
                />
              )}
            </motion.button>
            <motion.button
              onClick={() => setActiveTab('recommended')}
              className={`px-4 py-1.5 font-medium relative ${
                activeTab === 'recommended'
                  ? 'primary-text'
                  : 'secondary-text accent-hover'
              }`}
              whileTap={{ scale: 0.98 }}
            >
              Recommended
              {activeTab === 'recommended' && (
                <motion.span
                  layoutId="underline"
                  className="absolute left-0 bottom-0 h-1 secondary-bg w-full"
                />
              )}
            </motion.button>
          </div>

          <div className="flex items-center h-full space-x-3">
            <div className="relative py-1.5">
              <motion.div
                className="flex items-center relative"
                initial={false}
                animate={{ width: isSearchOpen ? '250px' : '40px' }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <AnimatePresence>
                  {isSearchOpen && (
                    <motion.input
                      ref={inputRef as React.Ref<HTMLInputElement>}
                      key="searchInput"
                      type="text"
                      className="w-full bg-transparent primary-text focus:outline-none"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      initial={{ x: '100%', opacity: 0, width: 0 }}
                      animate={{ x: 0, opacity: 1, width: '100%' }}
                      exit={{ x: '100%', opacity: 0, width: 0 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    />
                  )}
                </AnimatePresence>
                <motion.button
                  onClick={handleSearchClick}
                  className="p-2 transition-colors rounded-full absolute right-0"
                  aria-label="Search notes"
                  whileTap={{ scale: 0.98 }}
                >
                  <Search className="w-5 h-5 secondary-text" />
                </motion.button>
              </motion.div>
              <motion.div
                className="absolute left-0 bottom-0 h-0.5 w-full secondary-bg origin-left"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: isSearchOpen ? 1 : 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              />
            </div>

            <div className="flex">
              <motion.button
                onClick={() => setViewMode('grid')}
                className={`p-1.5 rounded-full transition-colors ${
                  viewMode === 'grid' ? 'primary-text' : 'secondary-text'
                }`}
                aria-label="Grid view"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Grid className="w-4 h-4" />
              </motion.button>
              <motion.button
                onClick={() => setViewMode('list')}
                className={`p-1.5 rounded-full transition-colors ${
                  viewMode === 'list' ? 'primary-text' : 'secondary-text'
                }`}
                aria-label="List view"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <List className="w-5 h-5" />
              </motion.button>
            </div>
          </div>
        </div>

        {/* Mobile/Tablet Tags */}
        <div className="flex lg:hidden overflow-x-auto px-2 mt-4 scrollbar-hide">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`px-3 py-1 mr-2 rounded-md text-xs font-normal transition-colors ${
              selectedCategory === 'all'
                ? 'border border-color bg-gray-200 primary-text'
                : 'border border-color secondary-text hover:bg-gray-100 dark:hover:bg-gray-700'
            } flex-shrink-0`}
            onClick={() => setSelectedCategory('all')}
          >
            All
          </motion.button>
          {categories.map(category => (
            <motion.button
              key={category.id}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className={`px-3 py-1 mr-2 rounded-md text-xs font-medium transition-colors ${
                selectedCategory === category.id
                  ? 'border border-color bg-gray-200 primary-text'
                  : 'border border-color secondary-text hover:bg-gray-100 dark:hover:bg-gray-700'
              } flex-shrink-0`}
              onClick={() => setSelectedCategory(category.id)}
            >
              {category.name}
            </motion.button>
          ))}
        </div>

        <div className="flex flex-col mt-1 md:mt-4 lg:flex-row-reverse items-start justify-between">
          <div
            ref={tagsRef as React.Ref<HTMLDivElement>}
            className={`w-full lg:w-1/4 mb-8 lg:mb-0 ${isTagsFixed ? 'lg:sticky lg:top-[64px] z-30' : ''}`}
          >
            <div>
              <div className="hidden lg:block my-2">
                <motion.button
                  onClick={() => setIsSignupOpen(true)}
                  className="w-full px-4 py-2 bg-transparent primary-text rounded-full border border-color transition-colors flex items-center justify-center hover:bg-black hover:primary-text hover:text-white hover:border-black dark:hover:bg-white dark:hover:text-black dark:hover:border-black"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="mr-2">Join Newsletter</span>
                  <ChevronRight className="h-4 w-4" />
                </motion.button>
              </div>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={listVariants}
                className="hidden lg:flex flex-wrap gap-0.5 p-2 mb-32 rounded-lg"
              >
                <motion.button
                  variants={listItemVariants}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`px-2 py-1 m-0.5 rounded-full text-xs font-normal transition-colors ${
                    selectedCategory === 'all'
                      ? 'border border-color secondary-bg primary-text'
                      : 'border border-color secondary-text hover:secondary-bg'
                  }`}
                  onClick={() => setSelectedCategory('all')}
                >
                  All
                </motion.button>
                {categories.map(category => (
                  <motion.button
                    variants={listItemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    key={category.id}
                    className={`px-3 py-1 m-0.5 rounded-full text-xs font-medium transition-colors ${
                      selectedCategory === category.id
                        ? 'border border-color secondary-bg primary-text'
                        : 'border border-color secondary-text hover:secondary-bg'
                    }`}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.name}
                  </motion.button>
                ))}
              </motion.div>
            </div>
          </div>

          <div className="w-full lg:w-3/4 lg:pr-8">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={listVariants}
              className={`${viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 gap-5' : 'space-y-4'}`}
            >
              {filteredNotes.map(note => (
                <motion.a
                  variants={listItemVariants}
                  key={note.id}
                  href={`/notes/${note.id}`}
                  className="block group transition-all"
                >
                  <motion.article
                    className={`h-full transition-all ${
                      viewMode === 'grid'
                        ? 'flex flex-col pb-4 border-b border-color'
                        : 'flex flex-row-reverse gap-6 items-center pb-4 border-b border-color'
                    }`}
                  >
                    <motion.div
                      className={`relative overflow-hidden ${
                        viewMode === 'grid'
                          ? 'h-48 w-full'
                          : 'h-32 w-32 flex-shrink-0'
                      } rounded-lg`}
                    >
                      <motion.img
                        src={note.image}
                        alt={note.title}
                        className="w-full h-full object-cover rounded-lg group-hover:scale-105 transition-transform"
                      />
                    </motion.div>
                    <div className={`p-4 flex flex-col ${viewMode === 'grid' ? '' : 'flex-1'}`}>
                      <h3 className="text-lg font-medium primary-text mb-2 transition-colors">
                        {note.title}
                      </h3>
                      <p className="text-sm secondary-text mb-4 line-clamp-2">
                        {note.excerpt}
                      </p>
                      <div className="mt-auto text-xs secondary-text">
                        <span>
                          {new Date(note.date)
                            .toLocaleDateString('en-US', {
                              month: 'short',
                              day: 'numeric',
                            })
                            .toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </motion.article>
                </motion.a>
              ))}
            </motion.div>
            <AnimatePresence>
              {filteredNotes.length === 0 && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="text-center py-12 bg-gray-50 dark:bg-gray-800/50 rounded-lg"
                >
                  <p className="text-lg secondary-text">
                    No articles found matching your criteria.
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="mt-4 px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-200 transition-colors"
                    onClick={() => {
                      setSearchQuery('');
                      setSelectedCategory('all');
                    }}
                  >
                    Reset Filters
                  </motion.button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <SubscribePopup isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} />
    </section>
  );
}
