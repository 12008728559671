import { motion } from 'framer-motion';

interface HeaderProps {
  title: string;
}

export const SectionHeader = ({ title }: HeaderProps) => {
  return (
    <motion.h2
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="text-4xl py-10 lg:p-14 sm:text-5xl font-thin text-center font-sans"
    >
      {title}
    </motion.h2>
  );
};

  
  /* .vintage .section-header {
    @apply font-bold;
    letter-spacing: 0;
  }
  
  .nature .section-header {
    @apply font-medium;
    letter-spacing: 0.02em;
  }
  
  .retro .section-header {
    @apply font-bold;
    letter-spacing: 0;
  }
  
  .future .section-header {
    @apply font-normal tracking-widest uppercase;
  }
  
  .autumn .section-header {
    @apply font-normal;
    letter-spacing: 0.01em;
  }
  
  .winter .section-header {
    @apply font-light tracking-wide;
  } */
  