// src/components/Banner.tsx

import { lazy } from "react";
import { motion } from "framer-motion";
import { Mail, ArrowRight, MapPin } from "lucide-react";
import { Link } from "react-router-dom";
import { RiTelegram2Fill, RiGithubFill, RiLinkedinFill } from "react-icons/ri";
import { GreetingRotator } from "../utils/Greetings";
import BannerArtifact from "./BannerArtifact"; // <-- import the artifact

const WinterScene = lazy(() => import("../utils/WinterScene.tsx"));

export function Banner() {
  const socialLinks = [
    { icon: Mail, href: "#", label: "Email" },
    { icon: RiGithubFill, href: "#", label: "GitHub" },
    { icon: RiTelegram2Fill, href: "#", label: "Telegram" },
    { icon: RiLinkedinFill, href: "#", label: "LinkedIn" },
  ];

  return (
    <section
      id="hero"
      className="relative min-h-screen flex px-4 md:px-0 items-center overflow-hidden"
    >
      <div className="absolute inset-0">
        <div className="absolute inset-0 winter:hidden bg-gradient-to-br from-purple-900/20 via-black/20 to-pink-900/20" />
        <div className="absolute inset-0 winter:hidden bg-grid-pattern opacity-5 animate-[pulse_4s_ease-in-out_infinite]" />

        {/* Winter mode background */}
        <div className="hidden winter:block absolute inset-0">
          <WinterScene />
        </div>
      </div>

      <div className="relative z-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8 flex-1"
          >
            <div className="space-y-4">
              <GreetingRotator />
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="text-3xl sm:text-4xl font-medium"
              >
                I work with cloud infrastructure, quantitative analysis and neural
                networks.
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
                className="text-lg secondary-text flex gap-2 items-center"
              >
                <MapPin className="w-5 h-5" /> Enim ad minim, Veniam
              </motion.p>
            </div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9 }}
              className="flex space-x-4"
            >
              {socialLinks.map((link) => (
                <motion.a
                  key={link.label}
                  href={link.href}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-3 bg-white/20 dark:bg-gray-800/30 rounded-full shadow-lg hover:shadow-xl transition-shadow"
                  aria-label={link.label}
                >
                  <link.icon className="w-6 h-6 text-gray-900 dark:text-gray-100" />
                </motion.a>
              ))}
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.1 }}
              className="flex space-x-4 font-sans"
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 gradient-bg text-white rounded-lg hover:shadow-lg transition-shadow flex items-center space-x-2 group"
              >
                <Link to="/portfolio" className="flex items-center space-x-2 group">
                  <span>View Portfolio</span>
                  <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </Link>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="gradient-border font-sans rounded-lg hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors"
                >
                <Link to="/about#contact" className="px-8 py-3 flex items-end">
                    Contact Me
                </Link>
               </motion.div>

            </motion.div>
          </motion.div>

          {/* Dark Mode Banner Artifact */}
          <BannerArtifact />
        </div>
      </div>
    </section>
  );
}
