import { useState, useEffect, useRef, FC, ReactNode } from 'react';
import { ThemeToggle } from '../utils/ThemeToggle';
import { Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

// Desktop nav item classes
const activeLinkClasses =
  "after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-0 after:bg-gray-600";
const baseLinkClasses =
  "relative active:scale-95 transition-colors text-sm font-medium hover:after:w-full after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-0 after:bg-gray-500 after:transition-[width] after:duration-300 after:ease-in-out";

interface NavItemProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
  extraClasses?: string;
}

// Desktop NavItem Component
const NavItem: FC<NavItemProps> = ({ to, children, onClick, extraClasses = "" }) => {
  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={({ isActive }) =>
        `${baseLinkClasses} ${isActive ? activeLinkClasses : ""} ${extraClasses}`
      }
    >
      {children}
    </NavLink>
  );
};

interface MobileNavItemProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
  extraClasses?: string;
}

// Mobile NavItem Component with inline style classes
const MobileNavItem: FC<MobileNavItemProps> = ({ to, children, onClick }) => {
  return (
    <NavLink
      to={to}
      onClick={onClick}
      className={`block py-2 text-4xl transition-colors`}
    >
      {children}
    </NavLink>
  );
};

export function Navbar() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  // Close menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMenuOpen]);

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-100 font-sans ${
        isScrolled ? 'navbar-bg shadow-lg' : ''
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
        <div className='justify-start z-50'>
          <Link to="https://tarique.me">
            <img
              src="https://raw.githubusercontent.com/probabilityzero/cloudstorage/main/T.png"
              alt="preview"
              className="inline-block mr-2 w-6 h-6 mb-1"
            />
          </Link>
          <NavLink
            to="/"
            className="text-xl transition-all duration-200 active:scale-95 font-medium"
          >
            Tarique
          </NavLink>
          </div>

          <div className="flex items-center space-x-4 md:space-x-6 z-50">
            <ThemeToggle />

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <motion.button
                onClick={toggleMenu}
                className="p-2  active:scale-95 transition-colors z-50"
                animate={{ rotate: isMenuOpen ? 90 : 0 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </motion.button>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <NavItem to="/about">About</NavItem>
              <NavItem to="/portfolio">Portfolio</NavItem>
              <NavItem to="/research">Research</NavItem>
              <Link
                to="https://notes.tarique.me"
                className="relative z-10 px-5 py-2 rounded-lg font-medium text-sm transition-all duration-300 
                  bg-gradient-to-r from-white via-gray-50 to-white dark:from-gray-950 dark:via-black dark:to-gray-900 
                  text-gray-900 dark:text-gray-50 border border-gray-300 dark:border-gray-700 
                  shadow-md dark:shadow-gray-900 hover:shadow-lg dark:hover:shadow-gray-800 
                  hover:bg-gradient-to-r hover:from-gray-100 hover:via-gray-200 hover:to-white 
                  dark:hover:from-gray-950 dark:hover:via-black dark:hover:to-gray-800 
                  hover:border-gray-400 dark:hover:border-gray-500 
                  before:-z-10 before:absolute before:inset-0 before:bg-gradient-to-r active:scale-95 
                  before:from-gray-50 before:via-white before:to-gray-100 
                  dark:before:from-gray-950 dark:before:via-black dark:before:to-gray-900 
                  before:rounded-lg before:opacity-0 
                  before:transition-opacity before:duration-300 before:hover:opacity-100 
                  hover:text-gray-900 dark:hover:text-gray-50"
              >
                Notes
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            ref={menuRef}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className="fixed inset-0 z-60"
          >
            {/* Mobile Header with Notes Link on Left */}


            {/* Mobile Menu Items */}
            <div className="flex flex-col h-screen secondary-bg">
              <div className="mt-16 border-t-2 border-gradient">
                <div className="flex flex-col space-y-4 mt-12 mr-8 text-right font-extrabold">
                  {/* Additional Notes link in mobile menu items */}
                  <MobileNavItem to="/portfolio" onClick={toggleMenu}>
                    Portfolio
                  </MobileNavItem>
                  <MobileNavItem to="/research" onClick={toggleMenu}>
                    Research
                  </MobileNavItem>
                  <MobileNavItem to="/about" onClick={toggleMenu}>
                    About
                  </MobileNavItem>
                  <MobileNavItem to="/contact" onClick={toggleMenu}>
                    Contact
                  </MobileNavItem>
                  <MobileNavItem to="https://notes.tarique.me" onClick={toggleMenu}>
                    Notes
                  </MobileNavItem>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}
